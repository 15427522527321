import React from "react";
import "./TitleText.scss";

class TitleText extends React.Component {
  fadeOut = () => {
    document.getElementById("title-block").classList.add("title-fade-in");
    document.getElementById("title-block").classList.add("title-fade-out");
  };
  fadeIn = () => {
    document.getElementById("title-block").classList.remove("title-fade-out");
  };
  robTheGameDev = () => {
    return (
      <div className="title-block-content">
        <hr className="decorator" />
        <div className="title-content">LiveOrDevTrying</div>
        <hr className="decorator" />
      </div>
    );
  };
  isLoading = () => {
    if (this.props.loading) {
      return (
        <div className="title-block-content">
          <div className="title-content">Is Loading</div>
        </div>
      );
    } else {
      return (
        <div className="title-block-content">
          <div className="title-content title-fade-out">Is Loading</div>
        </div>
      );
    }
  };
  render = () => {
    return (
      <div id="title-block" className="title-fade-in-initial">
        {this.robTheGameDev()}
        {this.isLoading()}
      </div>
    );
  };
}
export default TitleText;
